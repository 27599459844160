import { Box } from "@mui/system";
import { DialogAnimate } from "../animate";
import { Button, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { DataGridPro, GridColDef, GridFilterModel, GridFilterPanel, GridRowModel, GridSortModel, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { FilterTypeDTO, OperationValue } from "../../@types/DataGrid";
import { GET_DANAOS_LOGS_OF_BATCH } from "../../graphQL/Queries";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { makeStyles } from "@mui/styles";
import { parseDate, parseDateAndTime } from "../../utils/formatTime";


const useStyles = makeStyles(() => ({
  searchbar: {
    width: "200px",
  },
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
    "& .isHazardousCell.MuiDataGrid-cell--textCenter.MuiDataGrid-cell--withRenderer":
    {
      justifyContent: "flex-start",
    },
    "& .downloadDocumentsCell.MuiDataGrid-cell--textCenter.MuiDataGrid-cell--withRenderer":
    {
      justifyContent: "flex-start",
    },
  },
  panel: {
    "& .MuiNativeSelect-select": {
      backgroundColor: "red",
      color: "red",
    },
  },
  navigationIcon: {
    cursor: "pointer",
    marginLeft: "10px",
  },
}));




const useGetDanaosLogsOfBatchQuery = (
  batchId: number,
  limit: number,
  page: number,
  filtersArray: FilterTypeDTO[],
  sortField: string | undefined,
  sortOrder: string | undefined,
) => useQuery(GET_DANAOS_LOGS_OF_BATCH, {
  variables: {
    batchId: batchId,
    limit: limit,
    page: page,
    filtersArray: filtersArray,
    sortingOrder: {
      field: sortField,
      sort: sortOrder,
    },
  },
  onError(err) {
    toast.configure();
    toast.error(err.message);
  },
  skip: batchId == 0,
  fetchPolicy: "network-only",
});







//component prop types
type DanaosLogsOfDanaosBatchModalProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
  batchId: number;
};


export default function DanaosLogsOfDanaosBatchModal(
  props: DanaosLogsOfDanaosBatchModalProps
) {


  const {
    isOpenPreview,
    onClosePreview,
    batchId
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(0);
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [filtersArray, setFiltersArray] = useState<FilterTypeDTO[]>([]);
  const [refreshStateOfColumns, setRefreshStateOfColumns] = useState<boolean | undefined>(undefined);
  const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);
  const [totalRowsOfLogs, setTotalRowsOfLogs] = useState<number>(0);
  const [tableHeadOrdered, setTableHeadOrdered] = useState<GridColDef[]>([]);
  const [orderedColumns, setOrderedColumns] = useState<GridColDef[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [filtersPro, setFiltersPro] = React.useState<GridFilterModel>({
    items: [
      {
        field: "createdAt",
        operator: "contains"
      }
    ]
  });
  const [sortingPro, setSortingPro] = React.useState<GridSortModel>(
    [{ "field": "createdAt", "sort": "desc" }]
  );
  const classesNew = useStyles();
  const apiRef = useGridApiRef();

  const getDanaosLogsOfBatchQueryResponse = useGetDanaosLogsOfBatchQuery(batchId, rowsPerPage, page, filtersArray, sortField, sortOrder,);


  React.useEffect(() => {
    try {
      const TABLE_COLUMNS = [
        /*{
        field: "id",
        headerName: "Id",
        width: 80,
        type: "number",
        filterable: false,
        headerAlign: "center" as const                
        },*/
        {
          field: "createdAt",
          headerName: "Date of log",
          width: 210,
          type: "number",
          filterable: false,
          headerAlign: "center" as const
        },
        {
          field: "success",
          headerName: "Success",
          width: 80,
          type: "boolean",
          filterable: true,
          headerAlign: "center" as const
        },
        {
          field: "errorMessage",
          headerName: "Error Message",
          width: 230,
          type: "string",
          filterable: true,
          headerAlign: "center" as const
        },
        {
          field: "totalRows",
          headerName: "Total Rows",
          width: 160,
          type: "number",
          filterable: false,
          headerAlign: "center" as const
        },
        /*{
        field: "danaosBatchId",
        headerName: "Batch Id",
        width: 80,
        type: "number",
        filterable: false,
        headerAlign: "center" as const
        },*/
      ];

      const savedData = localStorage.getItem("gridStateOfDanaosLogOfABatchTable");

      if (savedData) {

        const savedDataJsonFormated = JSON.parse(savedData != undefined ? savedData : "{}");
        setOrderedColumns([]);


        if (TABLE_COLUMNS != undefined) {

          for (const field of savedDataJsonFormated.columns.orderedFields) {

            if (field != "__check__") {

              const savedColumn = TABLE_COLUMNS.find((obj) => {
                return obj.field === field;
              });
              if (savedColumn != undefined) {
                savedColumn.width = savedDataJsonFormated.columns.dimensions[field]["width"];
              }

              orderedColumns.push(savedColumn != undefined ? savedColumn : JSON.parse('{}'));
            }

          }

          setTableHeadOrdered(orderedColumns);


        }
      } else {
        setTableHeadOrdered(TABLE_COLUMNS);
      }

    } catch (error) {
      console.log(error)
    }
  }, [apiRef, isOpenPreview]);


  useEffect(() => {
    if (
      getDanaosLogsOfBatchQueryResponse &&
      getDanaosLogsOfBatchQueryResponse.data != undefined &&
      getDanaosLogsOfBatchQueryResponse.data
        .getDanaosLogsOfABatchId
    ) {
      const dataTableRowsArray: GridRowModel[] = [];


      for (const danaosLog of getDanaosLogsOfBatchQueryResponse.data.getDanaosLogsOfABatchId.rows) {
        const dataTableRow = {
          id: danaosLog.id,
          danaosBatchId: danaosLog.danaosBatchId,

          totalRows: danaosLog.totalRows,
          errorMessage: danaosLog.errorMessage,
          success: danaosLog.success,
          createdAt: typeof danaosLog.createdAt === "string" &&
          parseDateAndTime(danaosLog.createdAt) !== "Invalid Date"
            ? parseDateAndTime(danaosLog.createdAt)
            : danaosLog.createdAt

        };
        dataTableRowsArray.push(dataTableRow);


      }
      setTotalRowsOfLogs(getDanaosLogsOfBatchQueryResponse.data.getDanaosLogsOfABatchId.totalRows);

      setDataTableRows(dataTableRowsArray);
    }
  }, [getDanaosLogsOfBatchQueryResponse.data]);


  useEffect(() => {
    const { page, pageSize } = paginationModel;
    setPage(page);
    setRowsPerPage(pageSize);

  }, [paginationModel]);



  const exportState = React.useCallback(() => {
    setRefreshStateOfColumns(undefined);

    if (apiRef) {
      if (apiRef.current) {
        const state = apiRef.current.exportState();
        if (state) {

          if (window.localStorage) {
            localStorage.setItem("gridStateOfDanaosLogOfABatchTable", JSON.stringify(state));
          }

          setRefreshStateOfColumns(true);
        }
      }

    }

  }, [apiRef]);


  // filtering handler
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    const properFilterArray: FilterTypeDTO[] = [];
    setFiltersPro(filterModel);
    filterModel.items.forEach((filterObject) => {
      if (
        filterObject.field != undefined &&
        filterObject.operator != undefined &&
        filterObject.value != undefined
      ) {
        let opVal = OperationValue.UNKNOWN;
        switch (filterObject.operator) {
          case "contains":
            opVal = OperationValue.CONTAINS;
            break;
          case "equals":
            opVal = OperationValue.EQUALS;
            break;
          case "startsWith":
            opVal = OperationValue.STARTS_WITH;
            break;
          case "endsWith":
            opVal = OperationValue.ENDS_WITH;
            break;
          case "isEmpty":
            opVal = OperationValue.IS_EMPTY;
            break;
          case "isNotEmpty":
            opVal = OperationValue.IS_NOT_EMPTY;
            break;
          default:
            break;
        }
        const properFilter: FilterTypeDTO = {
          columnField: filterObject.field,
          operatorValue: opVal,
          value: filterObject.value,
        };
        properFilterArray.push(properFilter);
      }
    });
    if (properFilterArray.length > 0) {
      setFiltersArray(properFilterArray);
    }
  }, []);


  // sorting handler
  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortingPro(newModel);
    if (
      newModel !== null &&
      newModel != undefined &&
      newModel.length == 1 &&
      newModel[0].sort &&
      newModel[0].field
    ) {
      setSortField(newModel[0].field);
      setSortOrder(newModel[0].sort?.toString());
    }
  };

  const clearFiltersAndSorting = () => {
    setFiltersArray([]);
    setSortField("createdAt");
    setSortOrder("desc");
    setFiltersPro({

      items: [
        {
          field: "createdAt",
          operator: "contains"

        }
      ]
    });
    setSortingPro([{ "field": "createdAt", "sort": "desc" }]);
  }


  return (
    <Dialog
      open={isOpenPreview}
      onClose={onClosePreview}

      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1000px",
          },
        },
      }}
      fullWidth


    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 1,
        }}
      >
        <IconButton aria-label="delete" onClick={onClosePreview}>
          <Icon icon="akar-icons:circle-x" width={30} color={"#26567B"} />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 1 }}>
        <Button sx={{ m: "auto 0" }} variant="contained" onClick={() => clearFiltersAndSorting()}>Clear Filters</Button>
      </Box>
      <Box
        sx={{
          height: 550,
          width: 1,

          "& .super-app-theme--2": {
            bgcolor: () => "rgba(91, 229, 132, 0.15)",
          },
          "& .super-app-theme--1": {
            bgcolor: () => "rgba(255, 244, 165, 0.5)",
          },
        }}
      >

        <DataGridPro
          columns={tableHeadOrdered}
          rows={dataTableRows}
          className={classesNew.customStyle}
          onColumnVisibilityModelChange={exportState}
          onColumnOrderChange={exportState}
          onColumnWidthChange={exportState}
          apiRef={apiRef}
          components={{
            Toolbar: GridToolbar,
            FilterPanel: GridFilterPanel,
          }}
          // filtering props
          filterMode="server"
          filterModel={filtersPro}
          onFilterModelChange={onFilterChange}
          // sorting props
          sortingMode="server"
          sortModel={sortingPro}
          onSortModelChange={handleSortModelChange}
          // pagination props
          pagination
          paginationMode="server"
          rowCount={totalRowsOfLogs}
          pageSizeOptions={[20, 50, 100]}
          paginationModel={{ pageSize: rowsPerPage, page: page }}
          onPaginationModelChange={setPaginationModel}

          loading={getDanaosLogsOfBatchQueryResponse.loading}
        />
      </Box>

    </Dialog>
  );
}